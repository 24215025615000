import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const CollisDBZ = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Collis Billiards As DBZ Characters" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> Collis Billiards As DBZ Characters</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Mar. 7th, 2020) </p>
        </div>
        <div className="articleBody">
        <p><i>ZUFF//GOKU</i></p>
        <p>
        I mean, c'mon. This one was a given. 8-Ball, 9-Ball, 10-Ball, Cut-Throat, I went Kaioken, SSJ1+2+3, allat. I was running folks out the building with the sweat dripping down my brow -- check the credentials, pull my card, I dare you.
        </p>
        <p><i>BEDIAKO//VEGETA</i></p>
        <p>
        Again, a given. I miss my dog though, I hope he in the Hyperbolic Time Chamber cuz we know he gon need that to see me on the felt next time we link. Majin Bediako for the showdown, I know you reading this too bro, consider it on and popping, a tale as old as time lmao.
        </p>
        <p><i>ANDU//GOKU BLACK</i></p>
        <p>
        Yo I heard my dog work at Google now and I'm not surprised. Always had the super subdued Megamind swag, calculating the angles and shit but looking calm. I remember our saga though we used to really have the battles, I needed him to elevate on the lengthwise 8-ball bankers so yehmean not to reduce my dog to a plot-device but I was overcoming.
        </p>
        <p><i>EKSHEISH//BROLY</i></p>
        <p>
        This one...man. This might be the most accurate comparison I make outta all these. All I can say is this man used to really violate, I was traumatized, humbled oss. Not unrelated: if you learn anything from this, I want you to know never to gamble against Ethiopians or Filipinos when the cue stick is involved.
        </p>
        <p><i>JAY//JIREN</i></p>
        <p>
        Honestly, GT is canon lemme just say that first. That Super shit is for the birds, idk what tip y'all be on. But anyway this dude really used to go opposite of soft too, so I had to give him this one. I had his number though, ask him.
        </p>
        <p><i>AYUB//GOHAN</i></p>
        <p>
        My son. Y'all know how they said the creators was posed to shift the narrative to Gohan on some Boruto type sitcho? That's what this was, but I had to renege. My young really bossed up on me one too many times in the pool room though, I gotta admit it used to get under my skin here and there lmao.
        </p>
        <p><i>AKEEM//TRUNKS</i></p>
        <p>
        Lil bro got the MIP for sure, went from fumbling the break, scratching on easy money to traveling back in time to warn us about the Androids feel me?
        </p>
        <p><i>KIRK//BUU</i></p>
        <p>
        Super formidable. Super sturdy. Would love to go do-do-do-do with the chi blasts one more again against my bro.
        </p>
        <p><i>DARNELL//YAMCHA</i></p>
        <p>
        LMFAOOOOOO!!!
        </p>
        <p>
        Ard imma list the rest of these w/o comment cuz it's getting long:
        </p>
        <p>
        SANDILE//PICCOLO -- ISSA//FRIEZA -- THEO//CPT. GINYU -- HARRENSON//KRILLIN -- QUITA//MASTER ROSHI -- YESENIA//ANDROID 19
        </p>
        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>

    </div>

  );
};
export default CollisDBZ;
